<template>
  <div>
    <v-card
      class="overflow-hidden mb-5"
    >
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Avis étudiants
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiMessageDraw }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="reviews.length > 0">
        <v-data-table
          :headers="headers"
          :items="reviews"
          class="elevation-1"
          item-key="id"
          :search="search"
          @click:row="e => selected = e"
        >
          <template v-slot:top>
            <div v-if="selected">
            </div>
            <v-text-field
              v-model="search"
              label="Rechercher"
              class="mx-4"
            ></v-text-field>
          </template>
          <template
            #[`item.nom`]="{item}"
          >
            {{ item.nom }} {{ item.prenom }}
          </template>
          <template
            #[`item.student`]="{item}"
          >
            <div v-if="item.student">
              <v-btn
                color="primary"
                text
                @click="$router.push({name:'student-single', params:{id: item.student.id}})"
              >
                Voir profil
              </v-btn>
            </div>
            <div v-else>
              Pas relié
            </div>
          </template>
          <template
            #[`item.recommandation`]="{item}"
          >
            <v-chip
              v-if="item.recommandation"
              color="success"
              outlined
            >
              Recommande
            </v-chip>
            <v-chip
              v-else
              color="error"
              outlined
            >
              Ne recommande pas
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="primary"
          class="mb-5"
        >
          Button
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiMessageDraw } from '@mdi/js'

export default {
  data: () => ({
    selected: null,
    reviews: [],
    headers: [
      {
        text: 'Apprenant',
        align: 'start',
        sortable: true,
        value: 'nom',
      },
      {
        text: 'Note',
        align: 'center',
        sortable: true,
        value: 'note',
      },
      {
        text: 'Recommande',
        align: 'center',
        sortable: true,
        value: 'recommandation',
      },

      {
        text: 'Apprenant',
        align: 'center',
        sortable: true,
        value: 'student',
      },
    ],
    search: '',
    icons: {
      mdiMessageDraw,
    },
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchReviews()])
        .then(value => {
          [this.reviews] = value
          console.log(value)
        })
        .catch(err => console.log(err))
    },
    async fetchReviews() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/reviews`)

      return res.data
    },
  },
}
</script>

<style>

</style>
